<template>
  <custom-dialog :visible.sync="visible" :title="title" @close="colseDialog" @submit="submit">
    <el-form :model="editForm" label-width="80px" :rules="rules" ref="editHotelForm">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="所属酒店" prop="hotelId">
            <el-select v-model="editForm.hotelId" size="small" class="block-select" placeholder="请选择所属酒店"
                       @change="hotelChange">
              <el-option
                  v-for="item in hotelList"
                  :key="item.hotelId"
                  :label="item.hotelName"
                  :value="item.hotelId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="房间类型" prop="roomType">
            <el-select v-model="editForm.roomType" size="small" class="block-select" placeholder="请选择房间类型"
                       @change="findRoomInfo">
              <el-option
                  v-for="item in roomTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="房间楼层" prop="roomFloor">
            <el-input size="small" v-model="editForm.roomFloor" placeholder="请输入房间楼层" @blur="findRoomInfo"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="床位数" prop="roomBedCount">
            <el-input size="small" v-model="editForm.roomBedCount" placeholder="请输入床位数" @blur="findRoomInfo"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="房间编号" prop="roomId">
            <el-select v-model="editForm.roomId" size="small" class="block-select" placeholder="请选择房间编号">
              <el-option
                  v-for="item in roomInfoList"
                  :key="item.roomId"
                  :label="item.roomNo"
                  :value="item.roomId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="预留机构" prop="projId">
            <el-select v-model="editForm.projId" size="small" class="block-select" placeholder="请选择预留机构">
              <el-option
                  v-for="item in projectList"
                  :key="item.projId"
                  :label="item.projName"
                  :value="item.projId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="备注" prop="reseNote">
        <el-input size="small" type="textarea" v-model="editForm.reseNote" placeholder="请输入备注"></el-input>
      </el-form-item>
    </el-form>

  </custom-dialog>
</template>

<script>
import CustomDialog from "../../common/CustomDialog";
import accommodation from "../../../api/modules/accommodation";
import basic from "../../../api/modules/basic";
import {roomTypeList} from "../../../utils/hotelType"

export default {
  name: "EditReservationDialog",
  components: {CustomDialog},
  props: ['visible', 'title', 'item'],
  data() {
    return {
      editForm: {},
      rules: {
        hotelId: [
          {required: true, message: '请选择所属酒店', trigger: 'change'}
        ],
        roomId: [
          {required: true, message: '请选择房间编号', trigger: 'change'}
        ],
        projId: [
          {required: true, message: '请选择预留机构', trigger: 'change'}
        ],
        reseNote: [
          {required: true, message: '请输入备注', trigger: 'blur'}
        ],
      },
      hotelList: [],
      projectList: [],
      roomTypeList: roomTypeList,
      roomInfoList: []
    }
  },
  mounted() {
    this.getHotelInfo()
    this.getProjectList()
  },
  methods: {
    getProjectList() {
      basic.getProjectList().then(res => {
        this.projectList = res.data
      })
    },
    getHotelInfo() {
      accommodation.getHotelList().then(res => {
        this.hotelList = res.data
      })
    },
    colseDialog() {
      this.$emit('close')
    },
    submit() {
      this.$refs.editHotelForm.validate((valid) => {
        if (valid) {
          let params = {
            projId: this.editForm.projId,
            reseNote: this.editForm.reseNote,
            roomId: this.editForm.roomId
          }
          if (this.editForm.reseId) {
            // 木有编辑
            this.$emit('done')
          } else {
            accommodation.addRoomReserve(params).then(res => {
              this.$message({
                message: '新增成功',
                type: 'success'
              });
              this.$emit('done')
            })
          }
        }
      })
    },
    hotelChange(val) {
      this.findRoomInfo()
    },
    findRoomInfo() {
      let params = {
        hotelId: this.editForm.hotelId,
        roomBedCount: this.editForm.roomBedCount,
        roomFloor: this.editForm.roomFloor,
        roomType: this.editForm.roomType
      }
      accommodation.getAddRoomReserve(params).then(res => {
        this.roomInfoList = res.data
      })
    }
  }
}
</script>

<style scoped>

</style>